
import { TopoPageEntityModel, TopoPageQueryModel } from '../model/topo-page-entity-model';
import { ICRUDQ } from '@common-src/model/interface';
import { get, post, del } from '@common-src/service/request';
import localJsonData from '../assets/data/topo-data-szzx.json';

export const getTopoLocalData = function() {
    // const localTopoData = localStorage.getItem('topoData');
    // if (localTopoData) {
    //     try {
    //         return JSON.parse(localTopoData);
    //     } catch (err) {
    //         return localJsonData;
    //     }
    // }
    return localJsonData as any;
};
const IS_FROM_LOCAL = true;

const URL_PATH = `${BI_BASE_REQUEST_PATH}/topo`;
class TopoService implements ICRUDQ<TopoPageEntityModel, TopoPageQueryModel> {
    async create(model: TopoPageEntityModel):Promise<TopoPageEntityModel> {
        if (IS_FROM_LOCAL) {
            return new Promise((resolve) => {
                const localTopoData = getTopoLocalData();
                const currentTopoDataIndex = _.findIndex(localTopoData, (item: any) => item.id === model.id);
                if (currentTopoDataIndex > -1) {
                    localTopoData[currentTopoDataIndex] = model.toService();
                } else {
                    localTopoData.push(model.toService());
                }
                localStorage.setItem('topoData', JSON.stringify(localTopoData));
                resolve(model);
            });
        }
        const url = `${URL_PATH}/save`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async retrieve(modelId: string):Promise<TopoPageEntityModel> {
        if (IS_FROM_LOCAL) {
            return new Promise((resolve) => {
                resolve(new TopoPageEntityModel().toModel(_.find(getTopoLocalData(), item => item.id === modelId)));
            });
        }
        const url = `${URL_PATH}/detail/${modelId}`;
        const res = await get(url);
        return new TopoPageEntityModel().toModel(res);
    }

    async update(model: TopoPageEntityModel):Promise<TopoPageEntityModel> {
        const url = `${URL_PATH}/save`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async delete(model: TopoPageEntityModel):Promise<TopoPageEntityModel> {
        if (IS_FROM_LOCAL) {
            return new Promise((resolve) => {
                const localTopoData: Array<any> = getTopoLocalData();
                const currentTopoDataIndex = _.findIndex(localTopoData, (item: any) => item.id === model.id);
                if (currentTopoDataIndex > -1) {
                    localTopoData[currentTopoDataIndex] = model.toService();
                    localTopoData.splice(currentTopoDataIndex, 1);
                }
                localStorage.setItem('topoData', JSON.stringify(localTopoData));
                resolve(model);
            });
        }
        const url = `${URL_PATH}/delete/${model.id}`;
        const res = await del(url);
        return res;
    }

    async query(query?: TopoPageQueryModel, page?: number, limit?: number):Promise<any> {
        if (IS_FROM_LOCAL) {
            return new Promise((resolve) => {
                resolve(_.map(getTopoLocalData(), item => new TopoPageEntityModel().toModel(item)));
            });
        }
        const url = `${URL_PATH}/list`;
        const params = Object.assign({ page, limit }, query?.toService());
        const res = await post(url, params);
        res.items = _.map(res.items, item => item = new TopoPageEntityModel().toModel(item));
        return res;
    }
}

export default new TopoService();
